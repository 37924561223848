<ssi-message-box
  [activity]="activity"
  [isInbox]="isInbox"
  [isActive]="isActive"
  [isPrivate]="activity?.interaction.is_private"
  [isRoot]="!activity?.interaction.is_private && activity?.id === activity?.conversation?.root_thread_id"
  [isActionable]="isActionable"
  [canReply]="activity?.canReply"
  [meta]="{
    created_at: activity?.interaction.created_at,
    handle: activity?.author.username,
    author: activity?.author.name,
    author_outbox: activity?.outbox && activity?.outbox.author && activity?.outbox.author.fullName,
    source: activity?.interaction?.source,
    response_time: activity?.inbox?.is_actioned &&
    isInbox &&
    activity?.inbox.actioned_response_time &&
    !activity?.interaction.deleted_at &&
    !activity?.outbox && (activity?.inbox.actioned_response_time | secondsToHumanTime),
    network_icon: activity?.account?.socialNetwork.icon.web
  }"
  [avatar]="activity?.author.avatar"
  [tier]="!activity?.interaction.is_private && activity?.conversation?.depth"
  [stats]="!activity?.interaction.is_private && activity?.outbox && ([
    {
      value: activity?.outbox.like_count,
      iconClass: 'ssi-like-correct',
      tooltip: 'Total Likes',
      supported: activity?.account?.socialNetwork.statistics['likes'].outbox
    },
    {
      value: activity?.outbox.comment_count,
      iconClass: 'ssi-total-engagement',
      tooltip: 'Total Comments',
      supported: activity?.account?.socialNetwork.statistics['comments'].outbox
    },
    {
      value: activity?.outbox.share_count,
      iconClass: 'ssi-shares',
      tooltip: 'Number of Shares/Reposts',
      supported: activity?.account?.socialNetwork.statistics['shares'].outbox
    },
    {
      value: activity?.outbox.clicks,
      iconClass: 'ssi-clicks',
      tooltip: 'Number of Clicks',
      supported: activity?.account?.socialNetwork.statistics['clicks'].outbox
    },
    {
      value: activity?.outbox.impressions,
      iconClass: 'ssi-impressions',
      tooltip: 'Impressions',
      supported: activity?.account?.socialNetwork.statistics['impressions'].outbox
    },
    {
      value: activity?.outbox.reach,
      iconClass: 'ssi-reach',
      tooltip: 'Total Reach',
      supported: activity?.account?.socialNetwork.statistics['reach'].outbox
    },
    {
      value: activity?.outbox.campaign_id && campaign && campaign.name,
      iconClass: 'ssi-campaign',
      tooltip: activity?.outbox.campaign_id && activity?.outbox.campaign !== undefined && 'Campaign: ' + activity?.outbox.campaign.name,
      supported: true
    }
  ] | json)"
  [selfPenned]="isSelfPenned"
  [isLeft]="activity?.interaction.is_private ? !isSelfPenned : true"
  [retweetConfig]="{
    enabled: retweetEnabled(),
    currentAccount: {
      accountId: activity?.account_id,
      name: activity?.account?.displayName,
      disabled: activity?.social_actions?.is_shared
    },
    otherAccounts: workflowAccounts
  }"
  [translatable]="!translation && isInbox && activity?.properties.language !== currentLanguage"
  [attachments]="activity?.mediaByType"
  [enableActions]="true"
  [conversation]="conversation"
  [showConversationAssignTo]="inboxMode === 'conversation'"
  [conversationAssignedTo]="(inboxMode === 'conversation' && conversation) ? (conversation.assignedToUser || conversation.assignedToTeam) : null"
  [deleted]="{
    deleted_by: activity?.deletedBy,
    deleted_at: activity?.interaction.deleted_at
  }"
  [redacted]="{
    redacted_by: activity?.redactedBy,
    redacted_at: activity?.interaction.redacted_at
  }"
  [isHidden]="activity?.social_actions?.is_hidden"
  [sentiment]="activity && activity.sentiment ? activity.sentiment.value : false"
  [allTags]="allTags"
  [activeTags]="activity?.tags"
  [account]="activity?.account"
  [actionLikeConfig]="{
    display: !isSelfPenned && shouldShowActivityLink && activity?.social_actions?.can_like,
    status: activity?.social_actions?.is_liked ? activity?.account?.socialNetwork.socialInteractions.unlike.text : activity?.account?.socialNetwork.socialInteractions?.like.text,
    icon: activity?.social_actions?.is_liked ? activity?.account?.socialNetwork.socialInteractions.unlike.icon.web : activity?.account?.socialNetwork.socialInteractions?.like.icon.web
  }"
  [actionViewNatively]="shouldShowActivityLink && !activity?.interaction.deleted_at && activity?.interaction.link?.length > 1"
  [actionHideCommentConfig]="{
    display: (!isSelfPenned && shouldShowActivityLink && activity?.social_actions?.can_hide && !activity?.interaction.deleted_at) ||
             (!isSelfPenned && !activity?.interaction.deleted_at && activity?.social_actions?.is_hidden),
    status: activity?.social_actions?.is_hidden
  }"
  [actionDelete]="currentUserHasDeleteCommentPermissions(currentUser) && activity?.social_actions?.can_delete && !activity?.interaction.deleted_at && !activity?.isTempActivity"
  [actionRedact]="currentUserHasRedactMessagesPermissions(currentUser) && !isSelfPenned && !activity?.isTempActivity"
  [linkPreview]="activity?.linkPreview"
  [notes]="{
    display: isInbox && activity && !activity.isTempActivity && !activity.isConvertedToActivity,
    isActive: areActivityNotesVisible,
    amount: activity?.notes?.length || 0
  }"
  [unsupportedMessage]="!activity?.interaction.content && !activity?.media?.length"
  [mediaOnlyMessage]="!activity?.interaction.content && !!activity?.media?.length"
  [canSplitConversation]="!!canSplitConversation"
  [retweetSuccess]="retweetSuccess"
  [isConvertedToActivity]="activity?.isConvertedToActivity"
  (changeActiveTags)="activity?.setTags($event)"
  (changeSentiment)="activity?.changeSentiment($event)"
  (addNote)="activity?.addNote($event)"
  (showNotes)="toggleActivityNotesVisibility()"
  (viewNativelyClicked)="navigateTo(activity?.interaction.link)"
  (toggleShowHideCommentClicked)="activity?.toggleVisibility($event)"
  (toggleLikeClicked)="activity?.social_actions?.is_liked ? activity?.unlike() : activity?.like()"
  (deleteClicked)="activity?.remove()"
  (redactConfirmed)="activity?.redact()"
  (splitConversationPanelStatus)="splitConversationPanelStatus($event)"
  (splitConversation)="splitConversation.emit()"
  (retweet)="$event ? shareFromAccount($event) : autoShare()"
  (quoteRetweet)="quoteRetweet({create: {accounts: [activity?.account], quoteRetweet: activity?.interaction.link}})"
  (translate)="translateActivityContent()"
  (untranslate)="translation = null"
  (messageboxClicked)="onMessageboxClick.emit()"
>
  <ssi-activity-text
    [activity]="translation?.activity || activity"
    [cropContent]="cropActivityText"
    (onReadMoreClick)="handleReadMoreClick()"
    (onMentionClick)="viewProfile($event)"
  >
  </ssi-activity-text>
</ssi-message-box>

<div
  class="activity-notes-container"
  *ngIf="areActivityNotesVisible"
>
  <ssi-inline-notes
    *ngIf="activity?.notes?.length"
    [activity]="activity"
    [isAddNoteButtonVisible]="isAddNoteButtonVisible"
    [isExpanded]="areActivityNotesExpanded"
    [alignRight]="activity?.interaction.is_private ? isSelfPenned : false"
    (closeNotes)="areActivityNotesVisible = false"
  ></ssi-inline-notes>
</div>

<div
  class="activity-reply"
  *ngIf="reply.visible && activity?.canReply || reply.visible && activity?.interaction.social_type === 'review_trackers'"
  name="replyForm"
  [ngClass]="{'border-royal': reply.add_dm_reply_link,
             'activty-reply-sticky': replyBoxSticky,
             'activty-reply-sticky-margin-conversation': conversation,
             'social-wall': isSocialWall}"
>

  <div
    class="loading"
    *ngIf="reply.tracker.active"
  >
  </div>

  <ssi-reply-box
    (onReplyAccountChange)="changeReplyAccount($event)"
    (onKeyUp)="onKeyUp($event)"
    (onKeyDown)="onKeyDown($event)"
    (onSendReply)="saveReply($event)"
    (onUpdateReplyTextValue)="updateReplyText($event)"
    (onRemoveAttachment)="reply.file = null"
    [isInbox]="isInbox"
    [isSocialWall]="isSocialWall"
    [replyValue]="reply.text"
    [prediction]="prediction"
    [activity]="activity"
    [isDisabled]="replyBoxDisabled"
    [activityThread]="activityThread"
    [accountTypeName]="activity?.account.account_type_name"
    [mediaRestrictions]="activity?.account?.socialNetwork.mediaRestrictions.reply"
    [replyInProgress]="reply.tracker.active"
    (onFileUploadSuccess)="reply.file = $event"
    (onFileUploadError)="fileUploadErrored($event)"
    [shouldShowPushToCrm]="pluginPermission"
    (onPushToCrm)="pushMessagesToCrm()"
    [replyAccounts]="workflowAccounts"
    [replyAccount]="reply.account"
    [includableReplyToUsers]="reply.users"
    [replyingToVisible]="!activity?.interaction.is_private && activity?.account.account_type_name === 'Twitter' && reply.visible && activity?.canReply"
    [networkReplyOptions]="activity?.account?.socialNetwork.activity"
    [shouldShowTranslate]="!!translation"
    (onTranslate)="translateReplyText()"
    [attachments]="reply.file"
    [isTimedSession]="isTimed()"
    [daysToCountdown]="daysToCountdown"
    [sessionCreatedAt]="lastMessageReceivedAt"
    [supportsAddReplyLink]="!!activity?.account?.socialNetwork.getPrivateReplyLink"
    (onAddPrivateReplyLink)="addPrivateReplyLink()"
    (onTextareaFocus)="replyStarted()"
    [focusReply]="focusReply"
    [keyboardShortcuts]="keyboardShortcuts"
    [hideArrowShortcuts]="hideArrowShortcuts"
    (onKeyboardShortcut)="keyPressed($event)"
    (onReplyBoxStatusChange)="onReplyBoxStatusChanged.emit($event)"
    [conversation]="conversation"
    [isPushMode]="isPushMode"
    (onResolveConversation)="onResolve($event)"
    (onHoldConversationStatusChange)="holdConversationStatusChange($event)"
  ></ssi-reply-box>
</div>