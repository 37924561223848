<div class="upper-list"
     *ngIf="isLiveChatActive || (inboxQuery && !isSocialPushMode && !(inboxQuery.loading.initial.active || isLiveChatActive)) || (inboxQuery && isSocialPushMode && showActive && (inboxQuery.queryState.activePreset?.key === presets.active?.key || inboxQuery.queryState.activePreset?.key === presets.all?.key || !inboxQuery.queryState.activePreset))"
     [ngClass]="{'full-height': !isSocialPushMode || !showOnHold || showActive}">
  <div *ngIf="!isLiveChatActive || isSocialPushMode">

    <nav *ngIf="isSocialPushMode"
         class="inbox-search-results-options">
      <ul>
        <li class="social-push-item">
          <button uiSref="auth.inbox"
                  [uiParams]="{query: {assignment: ''} | json}"
                  [uiOptions]="{reload: true, inherit: false}">
            <i class="ssi ssi-keyboard-arrow-left"></i>
            <span translate="BACK_TO_CLASSIC_INBOX"></span>
          </button>
        </li>
      </ul>
    </nav>

    <nav class="inbox-search-results-options"
         [ngClass]="{ 'social-push-mode': isSocialPushMode }">
      <ul>
        <button type="button"
                class="sidebar-toggle"
                (click)="onSidebarButtonPress.emit()">
          <i class="fa fa-bars fa-inverse"></i>
        </button>
        <li class="inbox-search-results-btn-sort"
            ngbDropdown
            autoClose="outside">
          <ul class="dropdown-menu"
              ngbDropdownMenu>
            <li *ngFor="let option of sortOptions[inboxQuery.type]"
                (click)="refreshResultsFromOption(option)"
                [ngClass]="{ selected: option.value == inboxQuery.params.sort && option.order == inboxQuery.params.order }">
              <button>
                {{ option.translateLabel | translate }}

                <i class="ssi ssi-tick"
                   *ngIf="option.value == inboxQuery.params.sort && option.order == inboxQuery.params.order"></i>
              </button>
            </li>
          </ul>

          <button *ngIf="!isSocialPushMode"
                  ngbDropdownToggle>
            <ng-container *ngFor="let option of sortOptions[inboxQuery.type]">
              <span class="inbox-search-results-btn-label"
                    *ngIf="option.value == inboxQuery.params.sort && option.order == inboxQuery.params.order">
                {{ option.translateLabel | translate }}
              </span>
            </ng-container>
            <i class="ssi ssi-sort"></i>
          </button>
        </li>
      </ul>
    </nav>

    <div class="clearfix"></div>
  </div>
  <div *ngIf="isSocialPushMode && !isConversationThreadActive && (inboxQuery.queryState.activePreset?.key === presets.active?.key || inboxQuery.queryState.activePreset?.key === presets.all?.key || !inboxQuery.queryState.activePreset)"
       class="query-status-container push-mode-list-status-container">
    <i class="ssi ssi-pause query-status active"
       [ngClass]="{'no-results': activeConversationQuery?.result.list.length === 0}"></i>{{'ACTIVE' | translate}}
  </div>

  <ssi-live-chat [hasConversationPushModeEnabled]="hasConversationPushModeEnabled"
                 (isLiveChatActiveChange)="updateLiveChatStatus($event)"
                 [isLiveChatActive]="isLiveChatActive"
                 [ngClass]="{ 'expanded': isLiveChatActive }"
                 *ngIf="isLiveChatEnabled && !(inboxQuery.type === inboxQueryType.Activity && inboxQuery.conversation) && !isSocialPushMode"></ssi-live-chat>

  <!-- <div class="inbox-search-results-options-filters"
       [ngClass]="{ conversation: inboxQuery.type === inboxQueryType.Activity && inboxQuery.conversation, 'bottom-box-shadow': isLiveChatEnabled }"
       [hidden]="isLiveChatActive"
       *ngIf="isLiveChatActive && inboxQuery.queryState.activeFilters.length > 0 && !inboxQuery.queryState.activePresetIsCustom">
    <button class="inbox-clear-filters"
            (click)="inboxQuery.activatePreset({query: {}})">
      <span translate="CLEAR_FILTERS"></span>
      <i class="ssi ssi-times"></i>
    </button>
    <div class="clear-filters-copy">
      <span>To view messages outside your filters</span>
    </div>
  </div> -->

  <div class="inbox-search-results-conversation-bar"
       *ngIf="inboxQuery.type === inboxQueryType.Activity && inboxQuery.conversation && !isLiveChatActive">
    <div class="col-xs-5">
      <button class="btn btn-exit-conversation"
              (click)="exitConversation()">
        <i class="ssi ssi-exit-conversation"></i>

        <span translate="EXIT_CONVERSATION"></span>
      </button>
    </div>

    <ul class="col-xs-7">

      <li>
        <div class="inbox-search-results-conversation-total unread"
             container="body"
             popoverClass="popover-dark"
             placement="top"
             triggers="mouseenter mouseleave"
             ngbPopover="{{ 'UNREAD' | translate }}: {{ inboxQuery.conversation.count_unread }}">
          <i class="ssi ssi-new-message"></i>

          <div class="inbox-search-results-conversation-total-separator"></div>

          {{ inboxQuery.conversation.count_unread }}
        </div>
      </li>

      <li>
        <div class="inbox-search-results-conversation-total unactioned"
             container="body"
             popoverClass="popover-dark"
             placement="top"
             triggers="mouseenter mouseleave"
             ngbPopover="{{ 'UNACTIONED' | translate }}: {{ inboxQuery.conversation.count_unactioned }}">
          <i class="ssi ssi-fw ssi-unactioned"></i>

          <div class="inbox-search-results-conversation-total-separator"></div>

          {{ inboxQuery.conversation.count_unactioned }}
        </div>
      </li>

      <li>
        <div class="inbox-search-results-conversation-total actioned"
             container="body"
             popoverClass="popover-dark"
             placement="top"
             triggers="mouseenter mouseleave"
             ngbPopover="{{ 'ACTIONED' | translate }}: {{ inboxQuery.conversation.count_actioned }}">
          <i class="ssi ssi-actioned-new"></i>

          <div class="inbox-search-results-conversation-total-separator"></div>

          {{ inboxQuery.conversation.count_actioned }}
        </div>
      </li>

    </ul>
  </div>

  <ssi-inbox-query-result-list *ngIf="!isLiveChatActive"
                               (multiDeselectResult)="multiDeselectResult.emit($event)"
                               (multiSelectResult)="multiSelectResult.emit($event)"
                               [(activeConversationThread)]="activeConversationThread"
                               [isConversationThreadActive]="isConversationThreadActive"
                               [conversationThreadChange]="onConversationThreadChange"
                               (conversationEntered)="onConversationEntered('active')"
                               [canMultiSelectResults]="true"
                               [inboxMode]="inboxMode"
                               [inboxQuery]="isSocialPushMode ? activeConversationQuery : inboxQuery"
                               [multiSelectedResults]="multiSelectedResults"
                               [changeActivity]="onChangeActivity"
                               [selectedSortOption]="selectedSortOption"
                               [isSocialPushMode]="isSocialPushMode"
                               [activeList]="!!activeConversationQuery"></ssi-inbox-query-result-list>
</div>
<div class="lower-list"
     *ngIf="isSocialPushMode && holdConversationQuery && showOnHold && (inboxQuery.queryState.activePreset?.key === presets.onHold?.key || inboxQuery.queryState.activePreset?.key === presets.all?.key || !inboxQuery.queryState.activePreset)"
     [ngClass]="{'full-height': (showOnHold && !showActive) || inboxQuery.queryState.activePreset?.key === presets.onHold?.key}">
  <div class="push-mode-list-status-container"
       *ngIf="!isConversationThreadActive">
    <i class="ssi ssi-pause query-status on-hold"
       [ngClass]="{'no-results': holdConversationQuery.result.list.length === 0}"></i>{{'ON_HOLD' | translate}}
  </div>

  <div class="inbox-search-results-conversation-bar"
       *ngIf="inboxQuery.type === inboxQueryType.Activity && inboxQuery.conversation && !isLiveChatActive">
    <div class="col-xs-5">
      <button class="btn btn-exit-conversation"
              (click)="exitConversation()">
        <i class="ssi ssi-exit-conversation"></i>

        <span translate="EXIT_CONVERSATION"></span>
      </button>
    </div>
  </div>

  <ssi-inbox-query-result-list *ngIf="isSocialPushMode && (!!inboxQuery && !(inboxQuery.loading.initial.active || isLiveChatActive))"
                               (conversationEntered)="onConversationEntered('onHold')"
                               (multiDeselectResult)="multiDeselectResult.emit($event)"
                               (multiSelectResult)="multiSelectResult.emit($event)"
                               [(activeConversationThread)]="activeConversationThread"
                               [isConversationThreadActive]="isConversationThreadActive"
                               [conversationThreadChange]="onConversationThreadChange"
                               [canMultiSelectResults]="true"
                               [inboxMode]="inboxMode"
                               [inboxQuery]="holdConversationQuery"
                               [multiSelectedResults]="multiSelectedResults"
                               [changeActivity]="onChangeActivity"
                               [selectedSortOption]="selectedSortOption"
                               [isSocialPushMode]="isSocialPushMode"></ssi-inbox-query-result-list>
</div>