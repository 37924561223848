import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import './inbox-sidebar.component.scss';
import { User, UserModel } from '@ui-resources-angular';
import { InboxMode } from '../inbox.component';
import {
  InboxActivityQuery,
  InboxConversationQuery
} from '../../../../common/services/inbox-query-factory/inbox-query-factory.service';
import {
  Filter,
  FiltersComponent
} from '../../../../common/components/filters';
import { InboxQueryResultListItem } from '../../../../common/services/inbox-query-factory/queries/common';

@Component({
  selector: 'ssi-inbox-sidebar',
  templateUrl: './inbox-sidebar.component.html'
})
export class InboxSidebarComponent implements OnInit {
  @Input() activityQuery: InboxActivityQuery;
  @Input() inboxMode: InboxMode;
  @Input() inboxQuery: InboxActivityQuery | InboxConversationQuery;
  @Input() activeFilters: Filter[] = [];
  @Input() filtersComponentRef: FiltersComponent;
  @Input() isLiveChatActive: boolean;
  @Input() newMessagesFilteredCount: number;
  @Input() newMessagesTotalCount: number;
  @Input() selectedResults: InboxQueryResultListItem[];
  @Input() tutorialVideos: Array<any>;
  @Input() userEngagementStatistics: {
    actioned_by_user: number;
    assigned_to_user: number;
    average_response_time: number;
  };
  @Input() hasConversationPushModeEnabled: boolean;
  @Input() agentOfflineForSocial: boolean;
  @Input() isSocialPushMode: boolean;
  @Input() resultsOverlay: boolean;
  @Input() sidebarCollapsed: boolean;

  @Output() onDeselectSelectedResults = new EventEmitter();
  @Output() onEditFilter = new EventEmitter();
  @Output() onEditPreset = new EventEmitter();
  @Output() onExportInbox = new EventEmitter();
  @Output() onRemoveFilter = new EventEmitter();
  @Output() onShowVideoTutorial = new EventEmitter();
  @Output() onToggleBulkAction = new EventEmitter();
  @Output() onToggleFilters = new EventEmitter();
  @Output() onToggleInboxMode = new EventEmitter();
  @Output() onMarkAllActioned = new EventEmitter();
  @Output() onPresetActivated = new EventEmitter();
  @Output() onClose = new EventEmitter();

  @Output() onToggleActiveFilters = new EventEmitter();
  @Output() onTogglePresets = new EventEmitter();
  @Output() onToggleUsefulThings = new EventEmitter();

  sidebarFiltersExpanded: boolean;
  showBulkTip: boolean;
  showCustomPresets: boolean;
  activeFiltersVisible: boolean;
  filtersVisible: boolean;
  presetsVisible: boolean;
  usefulThingsVisible: boolean;

  constructor() {}

  async ngOnInit() {}

  activatePreset(preset) {
    this.inboxQuery.activatePreset(preset);
    if (this.isSocialPushMode) {
      this.onPresetActivated.emit();
    }
  }

  isActivityQuery(): boolean {
    return this.inboxQuery instanceof InboxActivityQuery;
  }
}
