<div class="inbox">
  <div class="inbox-columns">
    <div
      class="inbox-column-left"
      *ngIf="!isLiveChatActive && isSocialPushMode || !isSocialPushMode"
    >
      <ssi-inbox-sidebar
        *ngIf="queries"
        [resultsOverlay]="resultsOverlay"
        [sidebarCollapsed]="isSidebarCollapsed"
        [activityQuery]="queries?.activity.query"
        [inboxMode]="mode"
        [inboxQuery]="queries.active?.query"
        [activeFilters]="activeFilters"
        [filtersComponentRef]="filtersRef"
        [isLiveChatActive]="isLiveChatActive"
        [newMessagesFilteredCount]="queries.activity.newResults.filtered"
        [newMessagesTotalCount]="queries.activity.newResults.total"
        (onDeselectSelectedResults)="deselectAllResults()"
        (onEditFilter)="editFilter($event.filter)"
        (onEditPreset)="editPreset($event.preset)"
        (onExportInbox)="exportInbox()"
        (onRemoveFilter)="removeFilter($event.filter)"
        (onShowVideoTutorial)="showVideoTutorial($event.video)"
        (onToggleBulkAction)="toggleBulkAction($event.action)"
        (onToggleFilters)="toggleResultsOverlay(InboxResultOverlayType.Filters)"
        (onToggleActiveFilters)="toggleResultsOverlay(InboxResultOverlayType.ActiveFilters)"
        (onTogglePresets)="toggleResultsOverlay(InboxResultOverlayType.Presets)"
        (onToggleUsefulThings)="toggleResultsOverlay(InboxResultOverlayType.UsefulThings)"
        (onToggleInboxMode)="toggleInboxMode()"
        [selectedResults]="selectedResults"
        [tutorialVideos]="tutorialVideos"
        [userEngagementStatistics]="userEngagementStatistics"
        (onMarkAllActioned)="markAllActioned()"
        [hasConversationPushModeEnabled]="hasConversationPushModeEnabled"
        [agentOfflineForSocial]="agentOfflineForSocial"
        [isSocialPushMode]="isSocialPushMode"
        (onPresetActivated)="createPushModeConversationQueries()"
      ></ssi-inbox-sidebar>
    </div>

    <div
      [ngClass]="{ 'hidden-on-small-screens': areSearchResultsHidden }"
      class="inbox-column-middle"
    >
      <ssi-inbox-search-results
        *ngIf="queries"
        [activityQuery]="queries?.activity.query"
        [inboxMode]="mode"
        [inboxQuery]="queries.active?.query"
        [activeConversationQuery]="queries.activeConversation?.query"
        [holdConversationQuery]="queries.onHoldConversation?.query"
        [isConversationThreadActive]="isConversationThreadActive"
        (onConversationThreadChange)="onConversationThreadChange()"
        (isLiveChatActiveChange)="isLiveChatActive = $event"
        [isLiveChatActive]="isLiveChatActive"
        [isLiveChatEnabled]="isLiveChatEnabled"
        (multiDeselectResult)="deselectResult($event)"
        (multiSelectResult)="selectResult($event)"
        [multiSelectedResults]="selectedResults"
        [newMessagesUnfilteredCount]="queries.activity.newResults.unfiltered"
        [ngClass]="{ 'pinned-crm': crmPinned }"
        (onChangeActivity)="onChangeActivity()"
        (onExitConversation)="onExitConversation()"
        (onExitLiveChat)="onExitLiveChat()"
        (onSidebarButtonPress)="toggleSidebarCollapsed(!isSidebarCollapsed)"
        (onResolveConversation)="onResolveConversation()"
        (reloadResults)="reloadResults()"
        [hasConversationPushModeEnabled]="hasConversationPushModeEnabled"
        [isSocialPushMode]="isSocialPushMode"
        [(showActive)]="showActive"
        [(showOnHold)]="showOnHold"
      ></ssi-inbox-search-results>

      <ssi-inbox-search-results-overlay
        class="overlay-top-partial bulk-action-overlay bulk-action-overlay-assign"
        [ngClass]="{expanded: bulkAction?.type === 'assign', 'activity-open': !!queries?.activity.query.activeResult.value}"
        [active]="bulkAction?.type === 'assign'"
        (onClose)="bulkAction = null"
      >
        <div
          class="inbox-search-results-overlay-container"
          *ngIf="bulkAction?.type === 'assign'"
        >
          <header>
            <span class="header-title">
              <span translate="BULK_ASSIGN"></span>

              <span>
                {{ selectedResults.length }}
              </span>
              <span *ngIf="mode === InboxMode.Activity">
                <span
                  class="text-lowercase"
                  *ngIf="selectedResults.length === 1"
                  translate="MESSAGE"
                ></span>

                <span
                  class="text-lowercase"
                  *ngIf="selectedResults.length !== 1"
                  translate="MESSAGES"
                ></span>
              </span>
              <span *ngIf="mode === InboxMode.Conversation">
                <span
                  class="text-lowercase"
                  *ngIf="selectedResults.length === 1"
                  translate="CONVERSATION_SINGULAR"
                ></span>

                <span
                  class="text-lowercase"
                  *ngIf="selectedResults.length !== 1"
                  translate="CONVERSATIONS"
                ></span>
              </span>
            </span>

            <button
              class="button-close"
              (click)="bulkAction = null"
            >
              <i class="fa fa-times"></i>
            </button>
          </header>

          <article>
            <input
              type="search"
              class="form-control form-control-minimal inbox-search-results-overlay-list-select-search"
              placeholder="{{ 'SEARCH_NAME_OR_EMAIL_ADDRESS' | translate }}"
              [(ngModel)]="bulkAction.listSearch"
              [ngModelOptions]="{standalone: true}"
            />

            <div class="inbox-search-results-overlay-list-select">
              <ssi-list-select
                class="query-builder-width"
                [(ngModel)]="bulkAction.selectedValue"
                [ngModelOptions]="{standalone: true}"
              >
                <ssi-list-select-option
                  *ngFor="let team of teams | sortBy:'name' | filterPartial: {name: bulkAction.listSearch}; trackBy: trackById"
                  [value]="team"
                >
                  {{ team.name }}

                  <i class="ssi ssi-fw ssi-users-group"></i>
                </ssi-list-select-option>
                <ssi-list-select-option
                  *ngFor="let colleague of colleagues | filter:{is_active: true} | sortBy:'fullName' | filterPartial: {fullName: bulkAction.listSearch, email_address: bulkAction.listSearch}; trackBy: trackById"
                  [value]="colleague"
                >
                  {{ colleague.fullName }}

                  <i class="ssi ssi-fw ssi-person-outline"></i>
                </ssi-list-select-option>
              </ssi-list-select>
            </div>
          </article>

          <footer>
            <ul class="options">
              <li>
                <button
                  class="button-primary"
                  (click)="assignSelectedResults(bulkAction.selectedValue)"
                  [disabled]="!bulkAction.selectedValue"
                  translate="BULK_ASSIGN"
                ></button>
              </li>
              <li>
                <button
                  class="button-primary"
                  (click)="unassignSelectedResults()"
                  translate="BULK_UNASSIGN"
                ></button>
              </li>
            </ul>
          </footer>
        </div>
      </ssi-inbox-search-results-overlay>

      <ssi-inbox-search-results-overlay
        class="overlay-top-partial bulk-action-overlay bulk-action-overlay-sentiment"
        [ngClass]="{expanded: bulkAction?.type === 'sentiment', 'activity-open': !!queries?.activity.query.activeResult.value}"
        [active]="bulkAction?.type === 'sentiment'"
        (onClose)="bulkAction = null"
      >
        <div
          class="inbox-search-results-overlay-container"
          *ngIf="bulkAction?.type === 'sentiment'"
        >
          <header>
            <span class="header-title">
              <span translate="BULK_SENTIMENT"></span>

              <span>
                {{ selectedResults.length }}
              </span>

              <span *ngIf="mode === InboxMode.Activity">
                <span
                  class="text-lowercase"
                  *ngIf="selectedResults.length === 1"
                  translate="MESSAGE"
                ></span>

                <span
                  class="text-lowercase"
                  *ngIf="selectedResults.length !== 1"
                  translate="MESSAGES"
                ></span>
              </span>
              <span *ngIf="mode === InboxMode.Conversation">
                <span
                  class="text-lowercase"
                  *ngIf="selectedResults.length === 1"
                  translate="CONVERSATION_SINGULAR"
                ></span>

                <span
                  class="text-lowercase"
                  *ngIf="selectedResults.length !== 1"
                  translate="CONVERSATIONS"
                ></span>
              </span>
            </span>

            <button
              class="button-close"
              (click)="bulkAction = null"
            >
              <i class="fa fa-times"></i>
            </button>
          </header>

          <article>
            <ssi-list-select
              class="query-builder-width"
              [(ngModel)]="bulkAction.selectedValue"
              [ngModelOptions]="{standalone: true}"
            >
              <ssi-list-select-option
                *ngFor="let sentiment of sentimentConfigIterable"
                [value]="sentiment.inbox.value"
              >
                <span translate="{{ sentiment.translateKey }}"></span>
              </ssi-list-select-option>
            </ssi-list-select>
          </article>

          <footer>
            <ul class="options">
              <li>
                <button
                  class="button-primary"
                  (click)="changeSentimentOnSelectedResults(bulkAction.selectedValue)"
                  [disabled]="!bulkAction.selectedValue && bulkAction.selectedValue !== 0"
                  translate="BULK_SENTIMENT"
                ></button>
              </li>
            </ul>
          </footer>
        </div>
      </ssi-inbox-search-results-overlay>

      <ssi-inbox-search-results-overlay
        class="overlay-top-partial bulk-action-overlay bulk-action-overlay-tag"
        [ngClass]="{expanded: bulkAction?.type === 'tag', 'activity-open': !!queries?.activity.query.activeResult.value}"
        [active]="bulkAction?.type === 'tag'"
        (onClose)="bulkAction = null"
      >
        <div
          class="inbox-search-results-overlay-container"
          [ngClass]="{'has-error': tagForm.invalid}"
          ngForm
          #tagForm="ngForm"
          *ngIf="bulkAction?.type === 'tag'"
        >
          <header>
            <span class="header-title">
              <span translate="BULK_TAG"></span>

              <span>
                {{ selectedResults.length }}
              </span>

              <span *ngIf="mode === InboxMode.Activity">
                <span
                  class="text-lowercase"
                  *ngIf="selectedResults.length === 1"
                  translate="MESSAGE"
                ></span>

                <span
                  class="text-lowercase"
                  *ngIf="selectedResults.length !== 1"
                  translate="MESSAGES"
                ></span>
              </span>
              <span *ngIf="mode === InboxMode.Conversation">
                <span
                  class="text-lowercase"
                  *ngIf="selectedResults.length === 1"
                  translate="CONVERSATION_SINGULAR"
                ></span>

                <span
                  class="text-lowercase"
                  *ngIf="selectedResults.length !== 1"
                  translate="CONVERSATIONS"
                ></span>
              </span>
            </span>

            <button
              class="button-close"
              (click)="bulkAction = null"
            >
              <i class="fa fa-times"></i>
            </button>
          </header>

          <article>
            <input
              type="search"
              class="form-control form-control-minimal inbox-search-results-overlay-list-select-search"
              placeholder="{{ 'TYPE_YOUR_TAG_ELLIPSIS' | translate }}"
              [pattern]="tagsPattern"
              [(ngModel)]="bulkAction.listSearch"
              [ngModelOptions]="{standalone: true}"
              name="tagText"
            />

            <div class="inbox-search-results-overlay-list-select">
              <ssi-list-select
                class="query-builder-width"
                [(ngModel)]="bulkAction.selectedValue"
                [ngModelOptions]="{standalone: true}"
                [multiple]="true"
              >
                <ssi-list-select-option
                  *ngFor="let tag of tags | filterByString: bulkAction.listSearch"
                  [value]="tag"
                >
                  {{ tag }}
                </ssi-list-select-option>
              </ssi-list-select>
            </div>
          </article>

          <footer>
            <ul class="options">
              <li>
                <button
                  (click)="createTag(bulkAction.listSearch)"
                  *ngIf="bulkAction.listSearch && userCanCreateNewActivityTags"
                >
                  <span>
                    {{ bulkAction.listSearch }}
                  </span>

                  <span> (<span translate="CREATE_NEW"></span>) </span>
                </button>
              </li>

              <li>
                <button
                  class="button-primary"
                  (click)="tagSelectedResults(bulkAction.selectedValue)"
                  [disabled]="!bulkAction.selectedValue"
                  translate="BULK_TAG"
                ></button>
              </li>
            </ul>
          </footer>
        </div>
      </ssi-inbox-search-results-overlay>

      <ssi-inbox-search-results-overlay
        [ngClass]="{expanded: resultsOverlay === InboxResultOverlayType.Filters, 'activity-open': !!queries?.activity.query.activeResult.value}"
        [active]="resultsOverlay === InboxResultOverlayType.Filters"
        (onClose)="reloadResults()"
      >
        <!-- <ssi-query-builder
          *ngIf="resultsOverlay === InboxResultOverlayType.Filters"
          [(ngModel)]="queries.active?.query.apiQuery"
          (ngModelChange)="queries.active?.query.updateQueryState()"
          [filters]="queries.active?.query.filters"
          [inboxMode]="mode"
          (search)="reloadResults()"
          [queryState]="queries.active?.query.queryState"
          [onlyBuildQuery]="false"
          [canSaveAsPreset]="true"
          [version]="2"
          [activeFilter]="editing.filter"
          [editEreset]="editing.preset"
          (onClose)="toggleResultsOverlay(InboxResultOverlayType.Filters)"
          (onSaveAsPreset)="saveCustomPreset(label, query, edit)"
          (onDeletePreset)="deletePreset(preset)"
          (onToggleInboxMode)="toggleInboxMode()"
        ></ssi-query-builder> -->

        <div class="filters-container">
          <div class="filters-head"
               [class.tabs-visible]="queries.active !== queries.activity">
            <i class="fa fa-times close-icon"
               (click)="filtersRef.apply($event);"></i>
            <div class="heading">
              <h3>{{mode === InboxMode.Conversation ? 'Filter your conversations' : 'Filter your inbox'}}</h3>
            </div>
            <div class="tabs"
                 *ngIf="queries.active !== queries.activity">
              <div class="tab left"
                   [class.active]="queries.active !== queries.activity"><span>Conversations</span></div>
              <div class="tab right"
                   [class.active]="queries.active === queries.activity"
                   (click)="toggleInboxMode()"><span>Individual messages</span></div>
            </div>
          </div>
          <div class="filters-body">
            <ssi-filters #filtersRef
                         [config]="queries.active === queries.activity ? 'inboxMessage' : 'inboxConversation'"
                         [standalone]="false"
                         [visible]="true"
                         [applyOnInit]="false"
                         [savedApiParams]="queries.active?.query.apiQuery"
                         (onFiltersChange)="activeFilters = $event;"
                         (onBeforeFilterClear)="onBeforeFilterClear($event)"
                         (onApply)="onApplyFilters($event)"></ssi-filters>
          
            <!-- <div class="mode-hint"
                 *ngIf="mode === InboxMode.Conversation">
              <i class="ssi ssi-filters-microcopy mode-hint-icon"></i>
              <p class="mode-hint-text">
                <span>Looking for your full set of filters? Some of them are only available when in the</span>
                <em (click)="toggleInboxMode()">Individual Message</em>
                <span>mode</span>
              </p>
            </div> -->
          </div>
          <div class="filters-foot">
            <div class="buttons">
              <button *ngIf="editing?.preset"
                      class="g-btn text-only-button underlined"
                      [disabled]="!activeFilters.length"
                      (click)="createPresetMenu.hide(); createPresetMenu.show($event); presetName = editing.preset.label">
                <span>Edit preset</span>
              </button>
              <button *ngIf="editing?.preset"
                      class="g-btn g-btn-secondary delete-btn"
                      (click)="deletePreset(editing.preset);">
                <span>Delete preset</span>
              </button>
              <button *ngIf="!editing?.preset"
                      class="g-btn text-only-button underlined"
                      [disabled]="!activeFilters.length"
                      (click)="createPresetMenu.hide(); createPresetMenu.show($event);">
                <span>Save preset</span>
              </button>
              <button *ngIf="!editing?.preset"
                      class="g-btn"
                      [disabled]="!filtersRef.appliedModelsChanged"
                      (click)="filtersRef.apply($event);">
                <span>Apply filters</span>
              </button>
            </div>
          </div>
        </div>

        <ssi-context-menu #createPresetMenu>
          <ng-template let-ctx>
            <div class="g-box-shadow-bottom create-preset-menu-container">
              <h4>Create preset</h4>
              <div class="input-group">
                <input class="form-control"
                       placeholder="Enter preset name..."
                       [(ngModel)]="presetName">
              
                <span class="input-group-btn">
                  <button class="btn btn-primary"
                          [disabled]="!presetName"
                          (click)="createPresetMenu.hide(); saveCustomPreset(presetName, filtersRef.toApiParams(), editing?.preset);">
                    <span>{{editing?.preset ? 'Save' : 'Create'}}</span>
                  </button>
                </span>
              </div>
            </div>
          </ng-template>
        </ssi-context-menu>

      </ssi-inbox-search-results-overlay>

      <ssi-inbox-search-results-overlay
        [ngClass]="{expanded: resultsOverlay === InboxResultOverlayType.Presets, 'activity-open': !!queries?.activity.query.activeResult.value}"
        [active]="resultsOverlay === InboxResultOverlayType.Presets"
        (onClose)="resultsOverlay = null"
      >
        <div *ngIf="resultsOverlay === InboxResultOverlayType.Presets">
          <div
            class="inbox-search-results-overlay-title"
            translate="MY_PRESETS"
          ></div>
          <div class="inbox-search-results-overlay-body">
            <div *ngIf="queries.active.query.customPresets.length > 0">
              <ul class="list-unstyled inbox-search-results-overlay-list">
                <li
                  *ngFor="let preset of queries.active.query.customPresets"
                  [ngClass]="{selected: queries.active.query.queryState.activePreset === preset}"
                >
                  <a
                    href="javascript:;"
                    class="inbox-search-results-overlay-list-item-icon-left"
                    (click)="editPreset(preset)"
                  >
                    <i class="ssi ssi-fw ssi-pencil"></i>
                  </a>
                  <a
                    href="javascript:;"
                    (click)="queries.active.query.activatePreset(preset)"
                  >
                    <span class="inbox-search-results-overlay-list-item-text">{{ preset.label }}</span>
                    <i
                      class="fa inbox-search-results-overlay-list-item-icon-right fa-fw "
                      [ngClass]="queries.active.query.queryState.activePreset === preset ? 'fa-circle' : 'fa-circle-thin'"
                    ></i>
                  </a>
                </li>
              </ul>
              <br />
            </div>
            <ul class="list-unstyled inbox-search-results-overlay-list">
              <li
                *ngFor="let preset of queries.active.query.presets"
                [ngClass]="{selected: queries.active.query.queryState.activePreset === preset}"
              >
                <a
                  href="javascript:;"
                  class="inbox-search-results-overlay-list-item-icon-left"
                >
                </a>
                <a
                  href="javascript:;"
                  (click)="queries.active.query.activatePreset(preset)"
                >
                  <span class="inbox-search-results-overlay-list-item-text">{{ preset.label }}</span>
                  <i
                    class="fa inbox-search-results-overlay-list-item-icon-right fa-fw"
                    [ngClass]="queries.active.query.queryState.activePreset === preset ? 'fa-circle' : 'fa-circle-thin'"
                  ></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </ssi-inbox-search-results-overlay>

      <ssi-inbox-search-results-overlay
        [ngClass]="{expanded: resultsOverlay === InboxResultOverlayType.ActiveFilters, 'activity-open': !!queries?.activity.query.activeResult.value}"
        [active]="resultsOverlay === InboxResultOverlayType.ActiveFilters"
        (onClose)="resultsOverlay = null"
      >
        <div *ngIf="resultsOverlay === InboxResultOverlayType.ActiveFilters">
          <div
            class="inbox-search-results-overlay-title"
            *ngIf="queries.active.query.queryState.activeFilters.length == 1"
            translate="_COUNT__FILTER_ADDED"
            [translateParams]="{count: queries.active.query.queryState.activeFilters.length}"
          ></div>

          <div
            class="inbox-search-results-overlay-title"
            *ngIf="queries.active.query.queryState.activeFilters.length > 1"
            translate="_COUNT__FILTERS_ADDED"
            [translateParams]="{count: queries.active.query.queryState.activeFilters.length}"
          ></div>

          <div class="inbox-search-results-overlay-body">
            <div>
              <ul class="list-unstyled inbox-search-results-overlay-list">
                <li *ngFor="let filter of queries.active.query.queryState.activeFilters">
                  <a
                    href="javascript:;"
                    class="inbox-search-results-overlay-list-item-icon-left pull-left"
                    (click)="removeFilter(filter)"
                  >
                    <i class="fa fa-fw fa-times"></i>
                  </a>
                  <a
                    href="javascript:;"
                    (click)="editFilter(filter)"
                  >
                    <span class="inbox-search-results-overlay-list-item-text">{{
                      queries.active.query.queryState.activeFiltersText[filter.key]
                      }}</span>
                    <i class="ssi inbox-search-results-overlay-list-item-icon-right ssi-fw ssi-pencil"></i>
                  </a>
                </li>
              </ul>
              <br />
            </div>
          </div>
        </div>
      </ssi-inbox-search-results-overlay>

      <div
        class="social-pushmode-loader-overlay"
        *ngIf="isSocialPushMode && pollingForConversationsToPush"
      >
        <div class="overlay-container">
          <div><i class="fa fa-spin fa-spinner"></i></div>
          <div>
            <p>One moment we're loading up your Push Mode conversations now...</p>
          </div>
        </div>
      </div>

      <ssi-inbox-useful-things
        class="inbox-useful-things-wrap"
        [ngClass]="{expanded: resultsOverlay === InboxResultOverlayType.UsefulThings}"
        [isPopupTemplate]="true"
        (onClose)="resultsOverlay = null"
        (onMarkAllActioned)="markAllActioned()"
        (onExportInbox)="exportInbox()"
      ></ssi-inbox-useful-things>
    </div>

    <div
      class="inbox-column-right"
      [ngClass]="{expanded: !!queries?.activity.query.activeResult.value}"
    >
      <ssi-inbox-dashboard
        [isSocialPushMode]="isSocialPushMode"
        [authUser]="authUser"
        [hasNewlyResolvedConversation]="hasNewlyResolvedConversation"
        *ngIf="authUser && !isLiveChatActive && !queries?.activity.query.activeResult.value"
        (onDismissConversationResolutionConfirmation)="onDismissConversationResolutionConfirmation()"
        [userEngagementStatistics]="userEngagementStatistics"
        [hasConversationPushModeEnabled]="hasConversationPushModeEnabled"
      ></ssi-inbox-dashboard>

      <ssi-live-chat-dashboard *ngIf="isLiveChatActive"></ssi-live-chat-dashboard>

      <div
        class="inbox-message-open"
        *ngIf="!isLiveChatActive"
      >
        <ssi-inbox-activity
          [ngClass]="{'pinned-crm': crmPinned}"
          [activity]="queries?.activity.query.activeResult.value?.result.activity"
          [authUser]="authUser"
          [conversation]="queries?.activity.query.conversation"
          [realtimeHelper]="realtimeHelper"
          [realtimeConversationHelper]="realtimeConversationHelper"
          [userCanCreateNewActivityTags]="userCanCreateNewActivityTags"
          [inboxMode]="mode"
          [isPushMode]="isSocialPushMode"
          [isReply]="isReply"
          (onChangeActivity)="loadActivity($event.activity)"
          (onChangeConversation)="afterSplitConversation($event.conversation, $event.activity)"
          (onClose)="closeActivity()"
          (onExitConversation)="onExitConversation()"
          (onExitActivity)="onExitActivity()"
          (onReloadResults)="queries.active.query.search()"
          (onResolveConversation)="onResolveConversation()"
          (onHoldConversationStatusChanged)="onHoldConversationStatusChanged()"
          (onShowFilters)="resultsOverlay = InboxResultOverlayType.Filters"
        ></ssi-inbox-activity>
      </div>
    </div>
  </div>
</div>