import './global-filters.component.scss';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  AfterViewInit,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import moment from 'moment';
import { Account, ActivityTags } from '@ui-resources-angular';

import {
  DateRange,
  DateRanges,
  commonDateRanges,
  commonDateRangesIterable
} from '../../../../../../common/constants/common-date-ranges';
import {
  AgeOption,
  ageOptions,
  AgeOptions,
  ageOptionsIterable
} from '../../../../../../common/constants/age-options';
import {
  emotions,
  emotionsIterable,
  Emotion,
  Emotions,
  emotionsIterableSortedAZ
} from '../../../../../../common/constants/emotions';
import {
  industries,
  Industry,
  Industries,
  industriesIterableSortedAZ
} from '../../../../../../common/constants/industries';
import {
  interests,
  Interest,
  Interests,
  interestsIterableSortedAZ
} from '../../../../../../common/constants/interests';
import {
  sentiments,
  sentimentsIterable,
  Sentiment,
  Sentiments
} from '../../../../../../common/constants/sentiments';
import { LANGUAGES } from '../../../../../../common/constants';
import { AuthProvider } from '../../../../../../common/services/account-auth/account-auth.service';
import {
  Colleague,
  MonitoringStream,
  Team
} from '../../../../../../common/services/api';
import { Option } from '../../../../../../common/components/dropdown-select-2/dropdown-select-2.component';
import { InsightsService, LocationTier } from '../../../insights.service';
import { format } from 'date-fns';
import { GlobalFunction } from 'lodash/common/lang';
import {
  colleagues,
  teams,
  authUser,
  workflowAccounts,
  accounts
} from '../../../../../common-resolves';

export interface InsightsFilter {
  [key: string]: {
    label: string;
    icon: string;
    expanded: boolean;
    key: string;
    title?: string;
    name?: string;
    filterSelections?: any;
    // filterSelections?: {
    //   value: boolean | string;
    //   label: string;
    //   id?: string;
    //   constant?: string;
    //   start?: any;
    //   end?: any;
    // }[];
    dateFilterSelections?: {
      start: string | Date;
      end: string | Date;
      label: string;
    };
    options?: any;
  };
}

export enum GlobalFiltersStyleMode {
  Compact = 'compact',
  Full = 'full'
}

export enum GlobalFiltersFunctionalityMode {
  Compact = 'compact',
  Full = 'full'
}

@Component({
  selector: 'ssi-global-filters',
  templateUrl: './global-filters.component.html',
  styles: []
})
export class GlobalFiltersComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input() teams: Team[];
  @Input() colleagues: Colleague[];
  @Input() filters: InsightsFilter;
  @Input() monitoringStreams: MonitoringStream[];
  @Input() accounts: Account[];
  @Input() stylesMode: GlobalFiltersStyleMode = GlobalFiltersStyleMode.Full;
  @Input() functionalityMode: GlobalFiltersFunctionalityMode =
    GlobalFiltersFunctionalityMode.Full;

  @Output() filterChanged = new EventEmitter();
  @Output() onClearFiltersClick = new EventEmitter<void>();
  @Output() onFiltersClick = new EventEmitter<void>();
  @Output() onApplyFilters = new EventEmitter();
  @Output() onKeywordFilter = new EventEmitter();

  GlobalFiltersStyleMode = GlobalFiltersStyleMode;
  GlobalFiltersFunctionalityMode = GlobalFiltersFunctionalityMode;

  dateRanges: DateRanges = commonDateRanges;
  dateRangesIterable: DateRange[] = commonDateRangesIterable;
  ageOptions: AgeOptions = ageOptions;
  ageOptionsIterable: AgeOption[] = ageOptionsIterable;
  selectedDateRange: DateRange = this.dateRanges.month;
  today: Date = moment().endOf('day').toDate();
  showCustomRange = false;
  tags: Option[] = [];
  keywordTags = [];
  keywordTagsCondition: string = 'match';
  languages: Option[] = [];
  selectedTags: Option[] = [];
  selectedLanguages: Option[] = [];

  locationTiers = LocationTier;
  locations: {
    countries: Option[];
    states: Option[];
    cities: Option[];
    localities: Option[];
  } = {
    countries: [],
    states: [],
    cities: [],
    localities: []
  };
  activeLocationSelectionTier: {
    country: any;
    state: any;
    city: any;
    locality: any;
  } = {
    country: null,
    state: null,
    city: null,
    locality: null
  };

  emotions: Emotions = emotions;
  emotionsIterable: Emotion[] = emotionsIterableSortedAZ;
  industries: Industries = industries;
  industriesIterable: Industry[] = industriesIterableSortedAZ;
  interests: Interests = interests;
  interestsIterable: Interest[] = interestsIterableSortedAZ;
  sentiments: Sentiments = sentiments;
  sentimentsIterable: Sentiment[] = sentimentsIterable;

  accountFilterTotal: number;
  savedStreamFilterTotal: number;

  authorFilters: string[] = [];
  authorString: string;

  enabledFilters = {
    accounts: false,
    age: false,
    assignedTo: false,
    author: false,
    bioKeywords: false,
    channel: false,
    city: false,
    contentType: false,
    country: false,
    emotion: false,
    gender: false,
    industries: false,
    influencers: false,
    interests: false,
    isPrivate: false,
    keyword: false,
    language: false,
    listeningPeriod: false,
    location: false,
    messageType: false,
    savedStreams: false,
    sentiment: false,
    tags: false
  };

  constructor(
    protected activityTags: ActivityTags,
    protected insightsService: InsightsService
  ) {}

  ngOnInit() {
    this.validateFilters(Object.keys(this.filters));
    this.populateTags();
    this.populateLanguages();
    this.showCustomRange =
      this.filters.listeningPeriod.dateFilterSelections.label === 'Custom'
        ? true
        : false;

    this.prepareLocationFilters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters) {
      this.purgeUnavailableAccountAndStreamSelections();
    }
  }

  ngAfterViewInit() {}

  validateFilters(filters) {
    for (const key of filters) {
      if (!Object.keys(this.enabledFilters).includes(key)) {
        throw new Error('Invalid Filter Option');
      } else {
        this.enabledFilters[key] = true;
      }
    }
  }

  purgeUnavailableAccountAndStreamSelections() {
    // Hides the deleted and permission filters from the totals
    this.accountFilterTotal = this.filters.accounts.filterSelections.filter(
      (filter) =>
        filter.constant !== 'deleted' && filter.constant !== 'permission'
    ).length;

    this.savedStreamFilterTotal = this.filters.savedStreams.filterSelections.filter(
      (filter) => filter.constant !== 'deleted'
    ).length;
  }

  prepareLocationFilters() {
    this.getCountriesOptions([]);

    if (
      this.filters.location &&
      this.filters.location.filterSelections &&
      Array.isArray(this.filters.location.filterSelections)
    ) {
      const countryFilter = this.filters.location.filterSelections.find(
        (f) => f.label === LocationTier.Country
      );
      const stateFilter = this.filters.location.filterSelections.find(
        (f) => f.label === LocationTier.State
      );
      const cityFilter = this.filters.location.filterSelections.find(
        (f) => f.label === LocationTier.City
      );
      const localityFilter = this.filters.location.filterSelections.find(
        (f) => f.label === LocationTier.Locality
      );
      if (countryFilter) {
        this.activeLocationSelectionTier.country = {
          id: countryFilter.value,
          label: countryFilter.value
        };
        this.getStatesOptions([
          { field: LocationTier.Country, eq: countryFilter.value }
        ]);
      }
      if (stateFilter && countryFilter) {
        this.activeLocationSelectionTier.state = {
          id: stateFilter.value,
          label: stateFilter.value
        };
        this.getCitiesOptions([
          {
            field: LocationTier.State,
            eq: stateFilter.value
          },
          {
            field: LocationTier.Country,
            eq: countryFilter.value
          }
        ]);
      }
      if (cityFilter && stateFilter && countryFilter) {
        this.activeLocationSelectionTier.city = {
          id: cityFilter.value,
          label: cityFilter.value
        };
        this.getLocalityOptions([
          {
            field: LocationTier.City,
            eq: cityFilter.value
          },
          {
            field: LocationTier.State,
            eq: stateFilter.value
          },
          {
            field: LocationTier.Country,
            eq: countryFilter.value
          }
        ]);
      }
      if (cityFilter && stateFilter && countryFilter && localityFilter) {
        this.activeLocationSelectionTier.locality = {
          id: localityFilter.value,
          label: localityFilter.value
        };
        this.getLocalityOptions([
          {
            field: LocationTier.Locality,
            eq: localityFilter.value
          },
          {
            field: LocationTier.City,
            eq: cityFilter.value
          },
          {
            field: LocationTier.State,
            eq: stateFilter.value
          },
          {
            field: LocationTier.Country,
            eq: countryFilter.value
          }
        ]);
      }
    }
  }

  closeAllExpanded() {
    for (const filter in this.filters) {
      this.filters[filter].expanded = false;
    }
  }

  closeAllExpandedAndToggle(key) {
    for (const filter in this.filters) {
      if (filter === key) {
        continue;
      }
      this.filters[filter].expanded = false;
    }
    this.filters[key].expanded = !this.filters[key].expanded;
  }

  populateLanguages(): void {
    this.languages = Object.entries(LANGUAGES)
      .filter(([code, label]) => code !== 'en-gb')
      .map(([code, label]) => ({ id: code, label }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  // updateLanguages(selectedLanguages: Option[]): void {
  //   this.selectedLanguages = selectedLanguages;
  // }

  removeAge(age) {
    this.filters.age.filterSelections = this.filters.age.filterSelections.filter(
      (_age) => _age.id !== age.id
    );
    this.filterChanged.emit(this.filters);
  }

  removeLanguage(lang) {
    this.filters.language.filterSelections = this.filters.language.filterSelections.filter(
      (_lang) => _lang.id !== lang.id
    );
    this.filterChanged.emit(this.filters);
  }

  populateTags(): void {
    this.activityTags.getTags().then((tags: string[]) => {
      this.tags = tags.map((tag) => {
        return {
          id: tag,
          label: tag
        };
      });
    });
  }

  async getAndSortLocationDropdownOptions(
    tier: LocationTier,
    locationFilters: { field: LocationTier; eq: string }[],
    dataSet: 'countries' | 'states' | 'cities' | 'localities'
  ) {
    const locations = await this.insightsService.getLocations(
      locationFilters,
      tier
    );

    const locationsSorted = locations.sort((a, b) => {
      const textA = a.toLowerCase();
      const textB = b.toLowerCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return (this.locations[dataSet] = locationsSorted.map((l) => {
      return {
        id: l,
        label: l,
        tier
      };
    }));
  }

  async getCountriesOptions(tierFilters: any[]) {
    this.getAndSortLocationDropdownOptions(
      LocationTier.Country,
      tierFilters,
      'countries'
    );
  }
  async getStatesOptions(tierFilters: any[]) {
    this.locations.cities = [];
    this.locations.states = [];
    this.locations.localities = [];

    this.getAndSortLocationDropdownOptions(
      LocationTier.State,
      tierFilters,
      'states'
    );
  }

  async getCitiesOptions(tierFilters: any[]) {
    this.locations.cities = [];
    this.locations.localities = [];
    this.getAndSortLocationDropdownOptions(
      LocationTier.City,
      tierFilters,
      'cities'
    );
  }

  async getLocalityOptions(tierFilters: any[]) {
    this.locations.localities = [];
    this.getAndSortLocationDropdownOptions(
      LocationTier.Locality,
      tierFilters,
      'localities'
    );
  }

  /**
   * Used for populating dropdown options as location dropdown selection grows / gets narrow
   * When each level is changed, all the children options are reset to undefined
   */
  public onLocationChange(location: {
    id: string;
    label: string;
    tier: LocationTier;
  }) {
    if (location.tier === LocationTier.Country) {
      this.filters.location.options[LocationTier.City] = undefined;
      this.filters.location.options[LocationTier.State] = undefined;
      this.filters.location.options[LocationTier.Locality] = undefined;
      this.activeLocationSelectionTier.country = location;
      this.getStatesOptions([
        { field: LocationTier.Country, eq: location.label }
      ]);
    } else if (location.tier === LocationTier.State) {
      this.filters.location.options[LocationTier.City] = undefined;
      this.filters.location.options[LocationTier.Locality] = undefined;
      this.activeLocationSelectionTier.state = location;
      this.getCitiesOptions([
        { field: LocationTier.State, eq: location.label },
        {
          field: LocationTier.Country,
          eq: this.activeLocationSelectionTier.country.label
        }
      ]);
    } else if (location.tier === LocationTier.City) {
      this.filters.location.options[LocationTier.Locality] = undefined;
      this.activeLocationSelectionTier.city = location;
      this.getLocalityOptions([
        { field: LocationTier.City, eq: location.label },
        {
          field: LocationTier.State,
          eq: this.activeLocationSelectionTier.state.label
        },
        {
          field: LocationTier.Country,
          eq: this.activeLocationSelectionTier.country.label
        }
      ]);
    }
    const alreadyInsideIndex = this.filters.location.filterSelections.findIndex(
      (f) => f.label === location.tier
    );
    if (alreadyInsideIndex > -1) {
      this.filters.location.filterSelections[alreadyInsideIndex] = {
        label: location.tier,
        value: location.id
      };
    } else {
      this.filters.location.filterSelections.push({
        label: location.tier,
        value: location.id
      });
    }
    this.filterChanged.emit(this.filters);
  }

  // updateTags(selectedTags: Option[]): void {
  //   this.selectedTags = selectedTags;
  // }

  removeTag(tag) {
    this.filters.tags.filterSelections = this.filters.tags.filterSelections.filter(
      (_tag) => _tag.id !== tag.id
    );
    this.filterChanged.emit(this.filters);
  }

  checkboxChecked(checked, label, field, constant?) {
    if (label === 'Select All') {
      for (const [key] of Object.entries(this.filters[field].options)) {
        this.filters[field].options[key] = checked;
        if (field === 'accounts') {
          this.filters[field].filterSelections = checked
            ? this.accounts.map((account) => ({
                value: checked,
                label: account.name,
                constant: account.id
              }))
            : [];
        } else if (field === 'savedStreams') {
          this.filters[field].filterSelections = checked
            ? this.monitoringStreams.map((stream) => ({
                value: checked,
                label: stream.name,
                constant: stream.id
              }))
            : [];
        }
      }
      this.filterChanged.emit(this.filters);
      return;
    }
    if (checked) {
      // if (label === 'Exclude All') {
      //   for (const option in this.filters[field].options) {
      //     this.filters[field].options[option] =
      //       option !== 'Exclude All' ? false : true;
      //   }
      //   this.filters[field].filterSelections = [];
      // }
      this.filters[field].filterSelections.push({
        value: checked,
        label,
        constant
      });
    } else {
      this.filters[field].filterSelections = this.filters[
        field
      ].filterSelections.filter((item) =>
        item.constant ? item.constant !== constant : item.label !== label
      );
    }
    this.filters[field].options['Select All'] = false;
    this.filterChanged.emit(this.filters);
  }

  toggleSelectAll(event, filterKey) {
    for (const [key, value] of Object.entries(
      this.filters[filterKey].options
    )) {
      this.filters[filterKey].options[key] = event;
    }
  }

  checkIfFiltersAreSelected() {
    let filtersSelected = false;
    Object.keys(this.filters).forEach((filterKey) => {
      if (
        (this.filters[filterKey].filterSelections &&
          this.filters[filterKey].filterSelections.length > 0) ||
        this.filters[filterKey].dateFilterSelections ||
        (this.keywordTags && this.keywordTags.length > 0)
      ) {
        filtersSelected = true;
      }
    });
    return filtersSelected;
  }

  clearFilterSelections(objKey, event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }

    if (objKey === 'listeningPeriod') {
      this.selectPresetDateRange(this.dateRanges.month);
      this.filters[objKey].dateFilterSelections = {
        label: null,
        start: '',
        end: ''
      };
    } else {
      this.filters[objKey].filterSelections = [];
    }

    if (this.filters[objKey].options) {
      for (const [key, value] of Object.entries(this.filters[objKey].options)) {
        this.filters[objKey].options[key] = false;
      }
    }
    this.purgeUnavailableAccountAndStreamSelections();
    this.filterChanged.emit(this.filters);
  }

  clearAllFilterSelections() {
    const omittedFilters = ['responseTime', 'listeningPeriod'];
    this.selectPresetDateRange(this.dateRanges.month);
    Object.keys(this.filters).forEach(
      (filterKey) =>
        !omittedFilters.includes(filterKey) &&
        this.clearFilterSelections(filterKey)
    );
    this.filterChanged.emit(this.filters);
  }

  closeAllFilters() {
    Object.keys(this.filters).forEach((filterKey) => {
      this.filters[filterKey].expanded = false;
    });
  }

  selectPresetDateRange(range: DateRange): void {
    this.selectedDateRange = this.dateRanges[range.id];
    this.filters.listeningPeriod.dateFilterSelections = {
      label: this.selectedDateRange.label,
      start: this.selectedDateRange.startString,
      end: this.selectedDateRange.endString
    };
    this.filterChanged.emit(this.filters);
  }

  onFlatpickrOpen() {
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 200);
  }

  hasValues(filterSelections): boolean {
    if (filterSelections && Object.keys(filterSelections).length === 0) {
      return false;
    }
    return !!Object.values(filterSelections).some((value) => !!value);
  }

  onStartDatePicked(start: Date): void {
    if (!start) {
      return;
    }
    this.filters.listeningPeriod.dateFilterSelections.start = start;
    this.filters.listeningPeriod.dateFilterSelections.label = `Listening period: ${format(
      start,
      'DD/MM/YY HH:mm'
    )} - ${format(
      this.filters.listeningPeriod.dateFilterSelections.end,
      'DD/MM/YY HH:mm'
    )}`;
    this.selectedDateRange = undefined;
    this.filterChanged.emit(this.filters);
  }

  onEndDatePicked(end: Date): void {
    if (!end) {
      return;
    }
    this.filters.listeningPeriod.dateFilterSelections.end = end;
    this.filters.listeningPeriod.dateFilterSelections.label = `Listening period: ${format(
      this.filters.listeningPeriod.dateFilterSelections.start,
      'DD/MM/YY HH:mm'
    )} - ${format(end, 'DD/MM/YY HH:mm')}`;
    this.selectedDateRange = undefined;
    this.filterChanged.emit(this.filters);
  }

  removeAuthor(author) {
    this.filters.author.filterSelections = this.filters.author.filterSelections.filter(
      (authorFilter) => authorFilter.value !== author
    );
    this.filterChanged.emit(this.filters);
  }

  addAuthor() {
    this.filters.author.filterSelections.push({
      label: this.authorString,
      value: this.authorString
    });
    this.authorString = '';
    this.filterChanged.emit(this.filters);
  }

  applyFilters() {
    this.closeAllExpanded();
    console.log(this.filters);
    this.onApplyFilters.emit(this.filters);
  }

  generateFilterClearTag(key) {
    return `Clear ${this.filters[key].filterSelections.length} filter${
      this.filters[key].filterSelections.length === 1 ? '' : 's'
    }`;
  }

  onKeywordFilterChange(event: { tags: Array<string>; condition: string }) {
    this.onKeywordFilter.emit(event);
    this.keywordTags = event.tags;
    this.filterChanged.emit(this.filters);
  }
}
