import { ErrorHandler, Injectable } from '@angular/core';
declare var TrackJS: any;

@Injectable()
export class TrackJsErrorHandler implements ErrorHandler {
  handleError(error: any) {
    console.warn(error.message);
    if ((window as any).TrackJS) {
      TrackJS.track(error.originalError || error);
    }
  }
}
